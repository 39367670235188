import React from "react";
import {Helmet} from "react-helmet";

const SEO = ({title, description}) => (
    <Helmet defer={false}>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="twitter:description" content={description} />
    </Helmet>
);

export default SEO;